"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var environment_1 = require("@/environments/environment");
var enums_1 = require("@/enums");
var PackageService = /** @class */ (function () {
    function PackageService(http) {
        this.http = http;
    }
    PackageService_1 = PackageService;
    PackageService.prototype.addPackageCSV = function (packages) {
        return this.http.post(PackageService_1.package_url + "/upload", packages);
    };
    PackageService.prototype.getPackageByBarcode = function (userId, orderId) {
        var params = { orderId: orderId };
        return this.http.get(PackageService_1.package_url + "/" + userId, { params: params });
    };
    PackageService.prototype.getAllPackages = function (userId) {
        return this.http.get(PackageService_1.package_url + "/" + userId);
    };
    PackageService.prototype.updateScanStatusByBarcode = function (userId, orderId, scanStatus) {
        if (scanStatus === void 0) { scanStatus = enums_1.ScanStatus.COMPLETE; }
        var params = { orderId: orderId };
        return this.http.put(PackageService_1.package_url + "/" + userId, { scanStatus: scanStatus }, { params: params });
    };
    PackageService.prototype.updateScanStatusById = function (userId, packageId, scanStatus) {
        if (scanStatus === void 0) { scanStatus = enums_1.ScanStatus.COMPLETE; }
        var params = { packageId: packageId };
        return this.http.put(PackageService_1.package_url + "/" + userId, { scanStatus: scanStatus }, { params: params });
    };
    PackageService.prototype.removePackages = function (userId) {
        return this.http.delete(PackageService_1.package_url + "/" + userId + "/delete");
    };
    var PackageService_1;
    PackageService.package_url = environment_1.environment.apiUrl + "/v1/api/packages";
    PackageService = PackageService_1 = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient])
    ], PackageService);
    return PackageService;
}());
exports.PackageService = PackageService;
