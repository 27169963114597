"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageUploadComponent = void 0;
var XLSX = require("xlsx");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var modal_1 = require("ngx-bootstrap/modal");
var string_1 = require("lodash/string");
var lodash_1 = require("lodash");
var services_1 = require("@/services");
var PackageUploadComponent = /** @class */ (function () {
    function PackageUploadComponent(formBuilder, packageService, authService, alertService, modalRef) {
        this.formBuilder = formBuilder;
        this.packageService = packageService;
        this.authService = authService;
        this.alertService = alertService;
        this.modalRef = modalRef;
        this.faSave = null;
        this.saveClick = new rxjs_1.Subject();
        this.loading = false;
        this.currentUser = this.authService.currentUserValue;
    }
    PackageUploadComponent.prototype.addPackages = function () {
        var _this = this;
        var emptyValueRepresentation = 'N/A';
        this.packages = this.packages.map(function (pkg) {
            var convertedObj = lodash_1.mapKeys(pkg, function (v, k) { return string_1.camelCase(k); });
            convertedObj.userId = _this.currentUser.userId;
            if (convertedObj.orderId === '') {
                convertedObj.orderId = emptyValueRepresentation;
            }
            if (convertedObj.driver === '') {
                convertedObj.driver = emptyValueRepresentation;
            }
            if (convertedObj.stopNumber === '') {
                convertedObj.stopNumber = emptyValueRepresentation;
            }
            if (convertedObj.address === '') {
                convertedObj.address = emptyValueRepresentation;
            }
            if (convertedObj.routeDate === '') {
                convertedObj.routeDate = emptyValueRepresentation;
            }
            if (convertedObj.longitude === '') {
                convertedObj.longitude = emptyValueRepresentation;
            }
            if (convertedObj.latitude === '') {
                convertedObj.latitude = emptyValueRepresentation;
            }
            return convertedObj;
        });
        this.subscription = this.packageService.addPackageCSV(this.packages)
            .pipe(operators_1.first())
            .subscribe(function () {
            _this.modalRef.hide();
            _this.alertService.success(_this.packages.length + " packages added successful", false);
            _this.loading = false;
            _this.saveClick.next();
        }, function (error) { return _this.alertService.error(error); });
    };
    PackageUploadComponent.prototype.ngOnInit = function () {
        this.packageForm = this.formBuilder.group({
            xlsFile: [null, forms_1.Validators.required]
        });
    };
    PackageUploadComponent.prototype.readAsJson = function () {
        return XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
    };
    PackageUploadComponent.prototype.readExcel = function (file) {
        var _this = this;
        var readFile = new FileReader();
        var ob = new rxjs_1.Observable(function (obs) {
            readFile.onload = function () {
                _this.storeData = readFile.result;
                var data = new Uint8Array(_this.storeData);
                var arr = [];
                for (var i = 0; i != data.length; i += 1)
                    arr[i] = String.fromCharCode(data[i]);
                var bstr = arr.join("");
                var workbook = XLSX.read(bstr, { type: 'binary' });
                var firstSheetName = workbook.SheetNames[0];
                _this.worksheet = workbook.Sheets[firstSheetName];
                _this.packages = _this.readAsJson();
                obs.next(true);
                obs.complete();
            };
        });
        readFile.readAsArrayBuffer(file);
        return ob;
    };
    PackageUploadComponent.prototype.onLoad = function (event) {
        var _this = this;
        this.loading = true;
        this.fileUploaded = event.target.files[0];
        this.readExcel(event.target.files[0]).subscribe(function (status) {
            if (status) {
                _this.loading = false;
            }
        });
    };
    PackageUploadComponent.prototype.onSubmit = function () {
        if (this.packageForm.invalid) {
            return;
        }
        this.loading = true;
        this.addPackages();
        this.loading = false;
    };
    PackageUploadComponent.prototype.onCancelClick = function () {
        this.modalRef.hide();
    };
    PackageUploadComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.saveClick.complete();
    };
    PackageUploadComponent = __decorate([
        core_1.Component({ template: require('./package-upload.component.html') }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            services_1.PackageService,
            services_1.AuthenticationService,
            services_1.AlertService,
            modal_1.BsModalRef])
    ], PackageUploadComponent);
    return PackageUploadComponent;
}());
exports.PackageUploadComponent = PackageUploadComponent;
