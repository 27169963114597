"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageManualAddComponent = void 0;
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var faExclamationCircle_1 = require("@fortawesome/free-solid-svg-icons/faExclamationCircle");
var modal_1 = require("ngx-bootstrap/modal");
var services_1 = require("@/services");
var enums_1 = require("@/enums");
var PackageManualAddComponent = /** @class */ (function () {
    function PackageManualAddComponent(formBuilder, packageService, authService, alertService, modalRef) {
        this.formBuilder = formBuilder;
        this.packageService = packageService;
        this.authService = authService;
        this.alertService = alertService;
        this.modalRef = modalRef;
        this.faSave = null;
        this.faForward = null;
        this.barcodeId = '';
        this.submitted = false;
        this.faExclamationCircle = faExclamationCircle_1.faExclamationCircle;
        this.loading = false;
        this.currentUser = this.authService.currentUserValue;
    }
    PackageManualAddComponent.prototype.ngOnInit = function () {
        this.packageForm = this.formBuilder.group({
            address: null,
            routeName: null
        });
    };
    Object.defineProperty(PackageManualAddComponent.prototype, "getFormControls", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.packageForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    PackageManualAddComponent.prototype.onSubmit = function () {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();
        this.loading = true;
        this.addPackage();
    };
    PackageManualAddComponent.prototype.addPackage = function () {
        var _this = this;
        var pkg = [
            {
                userId: this.currentUser.userId,
                address: this.getFormControls.address.value
                    ? this.getFormControls.address.value : 'N/A',
                driver: this.getFormControls.routeName.value
                    ? this.getFormControls.routeName.value : 'N/A',
                routeDate: new Date().toLocaleDateString(),
                stopNumber: 'N/A',
                orderId: this.barcodeId,
                scanStatus: enums_1.ScanStatus.COMPLETE
            }
        ];
        this.subscription = this.packageService.addPackageCSV(pkg)
            .pipe(operators_1.first())
            .subscribe(function () {
            _this.modalRef.hide();
            _this.alertService.success(_this.barcodeId + " package added successful", false);
            _this.loading = false;
        }, function (error) { return _this.alertService.error(error); });
    };
    PackageManualAddComponent.prototype.onCancelClick = function () {
        this.modalRef.hide();
    };
    PackageManualAddComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    PackageManualAddComponent = __decorate([
        core_1.Component({ template: require('./package-manual-add.component.html') }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            services_1.PackageService,
            services_1.AuthenticationService,
            services_1.AlertService,
            modal_1.BsModalRef])
    ], PackageManualAddComponent);
    return PackageManualAddComponent;
}());
exports.PackageManualAddComponent = PackageManualAddComponent;
