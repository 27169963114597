"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var modal_1 = require("ngx-bootstrap/modal");
var faPowerOff_1 = require("@fortawesome/free-solid-svg-icons/faPowerOff");
var faUserCircle_1 = require("@fortawesome/free-solid-svg-icons/faUserCircle");
var faArchive_1 = require("@fortawesome/free-solid-svg-icons/faArchive");
var faQrcode_1 = require("@fortawesome/free-solid-svg-icons/faQrcode");
var package_json_1 = require("../../package.json");
var services_1 = require("./services");
var components_1 = require("@/components");
require("./content/app.less");
var AppComponent = /** @class */ (function () {
    function AppComponent(router, authenticationService, modalService) {
        var _this = this;
        this.router = router;
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.loading = false;
        this.version = package_json_1.version;
        this.faPowerOff = faPowerOff_1.faPowerOff;
        this.faUserCircle = faUserCircle_1.faUserCircle;
        this.faArchive = faArchive_1.faArchive;
        this.faQrcode = faQrcode_1.faQrcode;
        this.authenticationService.currentUser.subscribe(function (data) { return _this.currentUser = data; });
    }
    AppComponent.prototype.navigate = function (route) {
        this.router.navigate([route]);
    };
    AppComponent.prototype.isActiveRoute = function (url) {
        return this.router.url === url;
    };
    AppComponent.prototype.openLogoutModal = function () {
        var _this = this;
        this.loading = true;
        var modalRef = this.modalService.show(components_1.LogoutComponent, { ignoreBackdropClick: true });
        modalRef.content.title = 'Logout Account';
        modalRef.content.message = 'Are you sure you want to Logout?';
        modalRef.content.confirmIcon = this.faPowerOff;
        modalRef.content.saveClick.subscribe(function () {
            _this.authenticationService.logoutUser();
            _this.navigate('/login');
        });
    };
    AppComponent = __decorate([
        core_1.Component({ selector: 'app-root', template: require('./app.component.html') }),
        __metadata("design:paramtypes", [router_1.Router,
            services_1.AuthenticationService,
            modal_1.BsModalService])
    ], AppComponent);
    return AppComponent;
}());
exports.AppComponent = AppComponent;
