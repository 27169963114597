"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackagesListComponent = void 0;
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var json2csv_1 = require("json2csv");
var modal_1 = require("ngx-bootstrap/modal");
var faTrash_1 = require("@fortawesome/free-solid-svg-icons/faTrash");
var faFileImport_1 = require("@fortawesome/free-solid-svg-icons/faFileImport");
var faFileExport_1 = require("@fortawesome/free-solid-svg-icons/faFileExport");
var faCheck_1 = require("@fortawesome/free-solid-svg-icons/faCheck");
var faExclamation_1 = require("@fortawesome/free-solid-svg-icons/faExclamation");
var faMapMarkerAlt_1 = require("@fortawesome/free-solid-svg-icons/faMapMarkerAlt");
var enums_1 = require("@/enums");
var services_1 = require("@/services");
var components_1 = require("@/components");
var PackagesListComponent = /** @class */ (function () {
    function PackagesListComponent(modalService, authenticationService, alertService, packageService) {
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.alertService = alertService;
        this.packageService = packageService;
        this.packagesList = [];
        this.selectedRows = [];
        this.faFileImport = faFileImport_1.faFileImport;
        this.faFileExport = faFileExport_1.faFileExport;
        this.faTrash = faTrash_1.faTrash;
        this.faCheck = faCheck_1.faCheck;
        this.faExclamation = faExclamation_1.faExclamation;
        this.faMapMarkerAlt = faMapMarkerAlt_1.faMapMarkerAlt;
        this.loading = false;
        this.ScanStatus = enums_1.ScanStatus;
        this.currentUser = this.authenticationService.currentUserValue;
    }
    PackagesListComponent.prototype.updateSelectedRows = function () {
        var _this = this;
        this.packagesList.filter(function (pkg) {
            if (pkg.scanStatus === enums_1.ScanStatus.COMPLETE) {
                _this.selectedRows.push(pkg._id);
            }
        });
    };
    PackagesListComponent.prototype.refreshPackagesList = function () {
        var _this = this;
        this.subscription = this.packageService.getAllPackages(this.currentUser.userId)
            .pipe(operators_1.first()).subscribe(function (packages) {
            _this.packagesList = packages.data;
            _this.updateSelectedRows();
        });
    };
    PackagesListComponent.prototype.updatePackageStatus = function (packageId) {
        var _this = this;
        this.subscription = this.packageService.updateScanStatusById(this.currentUser.userId, packageId)
            .subscribe(function () { return _this.refreshPackagesList(); });
    };
    PackagesListComponent.prototype.ngOnInit = function () {
        this.refreshPackagesList();
    };
    PackagesListComponent.prototype.openImportNewModal = function () {
        var _this = this;
        this.loading = true;
        var modalRef = this.modalService.show(components_1.PackageUploadComponent, { ignoreBackdropClick: true });
        modalRef.content.faSave = this.faFileImport;
        modalRef.content.saveClick.subscribe(function () { return _this.refreshPackagesList(); });
    };
    PackagesListComponent.prototype.openExportModal = function () {
        this.loading = true;
        this.refreshPackagesList();
        var fields = ['orderId', 'driver', 'stopNumber', 'scanStatus', 'address'];
        var opts = { fields: fields };
        try {
            var csv = json2csv_1.parse(this.packagesList, opts);
            var anchorTag = document.createElement('a');
            var blob = new Blob([csv], { type: 'text/csv' });
            var url = window.URL.createObjectURL(blob);
            anchorTag.href = url;
            anchorTag.download =
                new Date().toLocaleDateString() + "_sorted_list.csv";
            anchorTag.click();
            window.URL.revokeObjectURL(url);
            anchorTag.remove();
        }
        catch (error) {
            console.log(error);
        }
    };
    PackagesListComponent.prototype.openRemoveModal = function () {
        var _this = this;
        this.loading = true;
        var modalRef = this.modalService.show(components_1.LogoutComponent, { ignoreBackdropClick: true });
        modalRef.content.title = 'Remove Packages';
        modalRef.content.message = 'Are you sure you want to remove the all packages on list?';
        modalRef.content.confirmIcon = this.faTrash;
        modalRef.content.saveClick.subscribe(function () { return _this.removeAllPackages(); });
    };
    PackagesListComponent.prototype.removeAllPackages = function () {
        var _this = this;
        var packagesSize = this.packagesList.length;
        this.subscription = this.packageService.removePackages(this.currentUser.userId)
            .pipe(operators_1.first())
            .subscribe(function () {
            _this.alertService.primary(packagesSize + " Packages removed successful", false);
            _this.refreshPackagesList();
        }, function (error) { return _this.alertService.error(error); });
    };
    PackagesListComponent.prototype.onRowSelectChange = function (pkgIds) {
        if (!this.packagesList || !this.packagesList.length) {
            return;
        }
        if (pkgIds && pkgIds.length) {
            var packageId = pkgIds[pkgIds.length - 1];
            this.updatePackageStatus(packageId);
        }
    };
    PackagesListComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    PackagesListComponent = __decorate([
        core_1.Component({ template: require('./packages-list.component.html') }),
        __metadata("design:paramtypes", [modal_1.BsModalService,
            services_1.AuthenticationService,
            services_1.AlertService,
            services_1.PackageService])
    ], PackagesListComponent);
    return PackagesListComponent;
}());
exports.PackagesListComponent = PackagesListComponent;
