"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppModule = void 0;
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var forms_1 = require("@angular/forms");
var http_1 = require("@angular/common/http");
var ornamentum_1 = require("ornamentum");
var modal_1 = require("ngx-bootstrap/modal");
var buttons_1 = require("ngx-bootstrap/buttons");
var ngx_scanner_1 = require("@zxing/ngx-scanner");
var angular_fontawesome_1 = require("@fortawesome/angular-fontawesome");
var app_component_1 = require("./app.component");
var app_routing_1 = require("./app.routing");
var components_1 = require("@/components");
var helpers_1 = require("@/helpers");
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule = __decorate([
        core_1.NgModule({
            imports: [
                platform_browser_1.BrowserModule,
                forms_1.ReactiveFormsModule,
                http_1.HttpClientModule,
                forms_1.FormsModule,
                modal_1.ModalModule.forRoot(),
                buttons_1.ButtonsModule.forRoot(),
                ornamentum_1.DataTableModule.forRoot(),
                angular_fontawesome_1.FontAwesomeModule,
                ngx_scanner_1.ZXingScannerModule,
                app_routing_1.appRoutingModule
            ],
            declarations: [
                app_component_1.AppComponent,
                components_1.RegisterComponent,
                components_1.LoginComponent,
                components_1.LogoutComponent,
                components_1.PackagesListComponent,
                components_1.BarcodeScannerComponent,
                components_1.PackageUploadComponent,
                components_1.PackageManualAddComponent,
                components_1.AlertComponent,
            ],
            providers: [
                { provide: http_1.HTTP_INTERCEPTORS, useClass: helpers_1.JwtInterceptor, multi: true },
                { provide: http_1.HTTP_INTERCEPTORS, useClass: helpers_1.ErrorInterceptor, multi: true },
            ],
            entryComponents: [
                components_1.PackageUploadComponent,
                components_1.PackageManualAddComponent,
                components_1.LogoutComponent
            ],
            bootstrap: [app_component_1.AppComponent]
        })
    ], AppModule);
    return AppModule;
}());
exports.AppModule = AppModule;
