"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appRoutingModule = void 0;
var router_1 = require("@angular/router");
var components_1 = require("@/components");
var helpers_1 = require("@/helpers");
var routes = [
    { path: '', component: components_1.PackagesListComponent, pathMatch: 'full', canActivate: [helpers_1.AuthGuard] },
    { path: 'scanner', component: components_1.BarcodeScannerComponent, canActivate: [helpers_1.AuthGuard] },
    { path: 'register', component: components_1.RegisterComponent },
    { path: 'login', component: components_1.LoginComponent },
    // otherwise redirect to packages-list
    { path: '**', redirectTo: '' }
];
exports.appRoutingModule = router_1.RouterModule.forRoot(routes);
