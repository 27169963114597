"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BarcodeScannerComponent = void 0;
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var modal_1 = require("ngx-bootstrap/modal");
var library_1 = require("@zxing/library");
var faFileImport_1 = require("@fortawesome/free-solid-svg-icons/faFileImport");
var faForward_1 = require("@fortawesome/free-solid-svg-icons/faForward");
var enums_1 = require("@/enums");
var services_1 = require("@/services");
var components_1 = require("@/components");
var BarcodeScannerComponent = /** @class */ (function () {
    function BarcodeScannerComponent(formBuilder, modalService, authenticationService, alertService, packageService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.alertService = alertService;
        this.packageService = packageService;
        this.faFileImport = faFileImport_1.faFileImport;
        this.faForward = faForward_1.faForward;
        this.isModalOpen = false;
        this.formatsEnabled = [
            library_1.BarcodeFormat.CODE_128,
            library_1.BarcodeFormat.DATA_MATRIX,
            library_1.BarcodeFormat.EAN_13
        ];
        this.currentUser = this.authenticationService.currentUserValue;
        this.modalService.onHidden.subscribe(function () { return _this.isModalOpen = false; });
        this.modalService.onShown.subscribe(function () { return _this.isModalOpen = true; });
    }
    BarcodeScannerComponent.prototype.updatePackageStatus = function (orderId) {
        this.putSubscription = this.packageService
            .updateScanStatusByBarcode(this.currentUser.userId, orderId).subscribe();
    };
    BarcodeScannerComponent.prototype.onCodeResult = function (orderId) {
        var _this = this;
        this.getSubscription = this.packageService
            .getPackageByBarcode(this.currentUser.userId, orderId)
            .pipe(operators_1.first())
            .subscribe(function (response) {
            if (!response.data && !_this.isModalOpen) {
                return _this.openManualAddModal(orderId);
            }
            var stopNo = response.data ? response.data.stopNumber : 'N/A';
            var driver = response.data ? response.data.driver : 'N/A';
            _this.alertService
                .primary("Order ID: " + orderId + ", Route: " + driver + ", Stop No: " + stopNo);
            if (response.data && response.data.scanStatus === enums_1.ScanStatus.PENDING) {
                _this.updatePackageStatus(orderId);
            }
        });
    };
    BarcodeScannerComponent.prototype.openManualAddModal = function (orderId) {
        var modalRef = this.modalService.show(components_1.PackageManualAddComponent, { ignoreBackdropClick: true });
        modalRef.content.barcodeId = orderId;
        modalRef.content.faSave = this.faFileImport;
        modalRef.content.faForward = this.faForward;
    };
    BarcodeScannerComponent.prototype.ngOnDestroy = function () {
        if (this.getSubscription) {
            this.getSubscription.unsubscribe();
        }
        if (this.putSubscription) {
            this.putSubscription.unsubscribe();
        }
    };
    BarcodeScannerComponent = __decorate([
        core_1.Component({ template: require('./barcode-scanner.component.html') }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            modal_1.BsModalService,
            services_1.AuthenticationService,
            services_1.AlertService,
            services_1.PackageService])
    ], BarcodeScannerComponent);
    return BarcodeScannerComponent;
}());
exports.BarcodeScannerComponent = BarcodeScannerComponent;
